<template>
    <CardComponent class="todo-card" :title="title" :icon="icon">
        <template v-slot:cardHeader>
            <i class="iconfont icon-jinrijihua" @click="showDayPlanDialog"></i>
            <el-dropdown>
                <i class="iconfont icon-gengduo"></i>
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item @click.native="showCheckDialog(null)">
                        日清检查
                    </el-dropdown-item>
                    <el-dropdown-item
                        divided
                        @click.native="$router.push('/todo/day-over-list')"
                    >
                        历史日清
                    </el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
        </template>

        <section class="todo__body">
            <transition name="fade-transform" mode="out-in">
                <div v-if="!pageSign" key="todo" class="todo__body--main">
                    <div class="todo__tabs">
                        <div class="todo__tabs--menu">
                            <el-dropdown
                                trigger="click"
                                @visible-change="menuVisibleChange"
                            >
                                <i class="iconfont icon-ego-menu"></i>
                                <el-dropdown-menu slot="dropdown">
                                    <el-dropdown-item
                                        v-for="menu in menuTypes"
                                        :key="menu.title"
                                        v-loading="visibleLoading"
                                        @click.native="
                                            handleClickMenu(menu.title)
                                        "
                                    >
                                        {{ menu.title }}（{{ menu.count }}）
                                    </el-dropdown-item>
                                    <el-dropdown-item @click.native="handleClickMenu('历史待办')">
                                        历史待办
                                    </el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown>
                        </div>
                        <div class="todo__tabs--item tabs">
                            <span
                                v-for="(tab, index) in tabs"
                                :key="index"
                                :class="{ actived: iscur === index }"
                                @click="changeTab(index)"
                            >{{ tab }}</span>
                        </div>
                    </div>
                    <template v-if="tabs[iscur] === '今日待办'">
                        <TodayTodo
                            ref="todayTodo"
                            :employee-list="provideObj.employeeList"
                        />
                    </template>
                    <template v-if="tabs[iscur] === '核心关注'">
                        <el-scrollbar style="flex: 1; margin-bottom: 0.2rem;">
                            <coreFocus
                                v-loading="coreLoading"
                                :employee-list="provideObj.employeeList"
                                :core-focus-list="coreFocusList"
                                :todo-status-dicts="provideObj.todoStatusDicts"
                                @handle-refresh="getCoreFocusList"
                            />
                        </el-scrollbar>
                    </template>
                    <template v-if="tabs[iscur] === '昨日日清'">
                        <el-scrollbar style="flex: 1; margin-bottom: 0.2rem;">
                            <yesterdayOver
                                :yesterday-over-list="yesterdayOverList"
                                @show-check-dialog="showCheckDialog"
                            />
                        </el-scrollbar>
                    </template>
                </div>
                <!-- 指派 -->
                <div v-else class="todo__body--main">
                    <template v-for="menu in menuTypes">
                        <!-- 我指派的 | 我参与的  -->
                        <todo-assign
                            v-if="menu.title === pageSign"
                            :key="menu.title"
                            :page-title="menu.title"
                            :todo-assign-data="menu.data"
                            @back-to-todo="backToTodo"
                        ></todo-assign>
                    </template>
                    <!-- 历史待办 -->
                    <history-todos v-if="pageSign=='历史待办'" page-title="历史待办" @back-to-todo="backToTodo"></history-todos>
                </div>
            </transition>
        </section>
        <!-- 日计划弹框 -->
        <dayPlanDialog
            ref="dayPlanDialog"
            :todo-status-dicts="provideObj.todoStatusDicts"
            @handle-refresh="getSeletedTodoList"
        />
        <!-- 日清检查弹框 -->
        <checkDialog
            ref="checkDialog"
            :todo-status-dicts="provideObj.todoStatusDicts"
            :sign="sign"
            @handle-refresh="getSeletedTodoList"
        />
        <!-- 新建待办 -->
        <addTodoDailog
            ref="addTodoDailog"
            @handle-refresh="getSeletedTodoList"
        />
    </CardComponent>
</template>

<script>
import CardComponent from '../components/card'
// import Empty from '@/components/empty'
import dayPlanDialog from '@/modules/todo/dayPlan/components/dayPlanDialog'
import addTodoDailog from '@/modules/todo/dayPlan/components/addTodoDailog'
import checkDialog from '@/modules/todo/dayOver/components/checkDialog'
import coreFocus from '@/modules/todo/pending/components/coreFocus'
import yesterdayOver from '@/modules/todo/dayOver/components/yesterdayOvers'
import TodoAssign from '@/modules/todo/pending/todoAssign' // 待办指派
import _ from 'lodash'
import todoSvc from 'services/todo'
import oaSvc from 'services/oa'
import comSvc from 'services/common'
import { getDateRangeDisplay } from '@/utils/date'
import moment from 'moment'
import TodayTodo from '@/modules/todo/pending'
import HistoryTodos from '@/modules/todo/pending/historyTodos' // 历史待办
import { mapGetters } from 'vuex'

import bus from 'utils/eventBus'

export default {
    name: 'PendingCard',
    components: {
        CardComponent,
        TodayTodo,
        dayPlanDialog,
        addTodoDailog,
        checkDialog,
        coreFocus,
        yesterdayOver,
        TodoAssign,
        HistoryTodos
    },
    data () {
        return {
            title: '待办',
            icon: 'iconfont icon-daibanshixiang',
            tabs: ['今日待办', '核心关注', '昨日日清'],
            // tabs: ['今日待办', '昨日日清'],
            iscur: 0,
            topList: [],
            notTopList: [],
            list: [],
            count: 0,
            // employeeList: [],
            yesterdayOverList: [],
            // todoStatusDicts: [],
            sign: null,
            coreFocusList: {}, // 核心关注数据
            coreLoading: false, // 核心关注的loading
            pageSign: null, // 标识这个卡片显示的是哪个页面 例如：今日待办 核心关注 我的指派等
            todoAssignData: {}, // 指派的待办数据 包含跟进中和历史的指派
            visibleLoading: false, // 菜单显示需要请求接口 所以增加loading
            takePartInData: {}, // 我参与的待办列表
            menuTypes: [
                // 按钮菜单
                {
                    title: '我指派的',
                    data: {},
                    count: 0
                },
                {
                    title: '我参与的',
                    data: {},
                    count: 0
                }
            ],
            provideObj: {}
        }
    },
    provide () {
        return {
            provideObj: this.provideObj
        }
    },
    computed: {
        ...mapGetters(['currentUser']),
        // 指派待办的数量
        totoAssignCount () {
            let count = 0
            if (this.todoAssignData && this.todoAssignData.followUp) {
                count += this.todoAssignData.followUp.length
            }
            // if (this.todoAssignData.participationHistory) {
            //     count += this.todoAssignData.participationHistory.length
            // }
            return count
        },
        takePartInCount () {
            let count = 0
            if (this.takePartInData && this.takePartInData.followUp) {
                count += this.takePartInData.followUp.length
            }
            return count
        }
    },
    watch: {
        iscur (newValue, oldValue) {
            const tabName = this.tabs[newValue]
            if (tabName === '昨日日清') {
                this.getYesterdayDayOvers()
            } else if (tabName === '核心关注') {
                this.getCoreFocusList()
            }
        }
    },
    created () {
        this.getEmployeeList()
        this.getTodoDictionary()
    },
    mounted () {
        bus.$on('refresh-todo-list', this.getSeletedTodoList)
        // 刷新指派
        bus.$on('refresh-assign-todo', this.getTodoAssignList)
    },
    methods: {
        moment,
        getDateRangeDisplay,
        // 获取待办事项状态的数据字典
        async getTodoDictionary () {
            const { data = [] } = await comSvc.getDictionary('待办事项状态')
            this.provideObj.todoStatusDicts = data.map((item) => {
                let obj = {}
                obj.itemValue = Number(item.itemValue)
                obj.title = item.title
                switch (obj.itemValue) {
                // 待处理
                case 0:
                    obj.class = 'iconfont icon-a-quandaibanweiwancheng'
                    break
                    // 已处理
                case 1:
                    obj.class = 'iconfont icon-yiwancheng'
                    break
                    // 已搁置
                case 2:
                    obj.class = 'iconfont icon-yigezhi'
                    break
                    // 已取消
                case -1:
                    obj.class = 'iconfont icon-yiquxiao'
                    break
                    // 已完成 待确认
                case 9:
                    obj.class = 'iconfont icon-yiwancheng assign'
                    break
                default:
                    obj.class = 'iconfont icon-a-quandaibanweiwancheng'
                    break
                }
                return obj
            })
        },
        // 获取公司员工
        async getEmployeeList () {
            try {
                const { data = [] } = await oaSvc.getEmlpoyeesList({
                    selectStatus: [0, 1]
                })
                this.provideObj.employeeList = data.map((item) => {
                    let obj = {}
                    obj.employeeId = item.employeeId
                    obj.employeeName = item.employeeName
                    return obj
                })
                this.provideObj.executorOption = this.provideObj.employeeList.filter(
                    (item) => item.employeeId != this.currentUser.employeeId
                )
            } catch (error) {
                console.log(error)
            }
        },
        getSeletedTodoList () {
            this.$nextTick(() => {
                const _ref = this.$refs['todayTodo']
                if (_ref) {
                    _ref.getTodoList()
                }
            })
            this.getTodoAssignList()
        },
        // async getDictionary () {
        //     const { data = [] } = await comSvc.getDictionary('待办事项状态')
        //     this.todoStatusDicts = data.map((item) => {
        //         let obj = {}
        //         obj.itemValue = Number(item.itemValue)
        //         obj.title = item.title
        //         return obj
        //     })
        // },
        changeTab (index) {
            this.iscur = index
        },
        showDayPlanDialog () {
            const _ref = this.$refs.dayPlanDialog
            _ref.visible = true
        },
        showAdd () {
            const _ref = this.$refs.addTodoDailog
            _ref.isEdit = false
            _ref.title = '新建待办'
            _ref.visible = true
        },
        showCheckDialog (sign, id) {
            // checkDialog
            this.sign = sign
            const _ref = this.$refs.checkDialog
            _ref.employeeList = this.provideObj.employeeList
            _ref.detailId = id
            _ref.todoStatusDicts = this.provideObj.todoStatusDicts
            _ref.visible = true
        },
        async getYesterdayDayOvers () {
            const { data = [] } = await todoSvc.getYesterdayDayOvers()
            this.yesterdayOverList = data
        },
        // 核心关注列表查询
        async getCoreFocusList () {
            try {
                this.coreLoading = true
                const { data = [] } = await todoSvc.getCoreFocusList()
                this.coreFocusList = data
                this.coreLoading = false
            } catch (error) {
                console.log(error)
                this.coreLoading = false
            }
        },
        handleClickMenu (sign) {
            this.pageSign = sign
        },
        // 左上角菜单图表显示/隐藏事件
        menuVisibleChange (visible) {
            // 出现则为 true，隐藏则为 false
            console.log(visible)
            if (visible) {
                this.getMenuList()
            }
        },
        // 获取指派待办列表
        async getTodoAssignList () {
            // this.visibleLoading = true
            const { data } = await todoSvc.getTodoAssignList()
            // this.visibleLoading = false
            this.menuTypes[0].data = data
        },
        // 获取我参与的待办列表
        async getTakePartInList () {
            this.visibleLoading = true
            const { data } = await todoSvc.getTodoAssignInvolved()
            this.visibleLoading = false
            this.todoAssignData = data
        },
        // 获取menuList
        async getMenuList () {
            this.visibleLoading = true
            const [res1, res2] = await Promise.all([
                todoSvc.getTodoAssignList(), // 指派
                todoSvc.getTodoAssignInvolved() // 参与
            ])
            this.menuTypes[0].data = res1.data
            this.menuTypes[0].count =
                res1.data && res1.data.followUp && res1.data.followUp.length > 0
                    ? res1.data.followUp.length
                    : 0
            this.menuTypes[1].data = res2.data
            this.menuTypes[1].count =
                res2.data && res2.data.followUp && res2.data.followUp.length > 0
                    ? res2.data.followUp.length
                    : 0
            this.visibleLoading = false
        },
        // 待办指派 返回今日待办页面
        backToTodo () {
            this.pageSign = null
        }
    }
}
</script>
<style lang="less" scoped>
.todo-card {
    grid-row-start: span 4;
    grid-column-start: span 1;
    // max-width: 3.6rem;

    i.icon-yiwancheng {
        color: #52c123;
    }

    i.icon-yigezhi {
        color: #e6a23c;
    }

    i.icon-yiquxiao,
    i.icon-weiwancheng {
        color: #df5a68;
    }

    /deep/ .right {
        display: flex;
        align-items: center;
    }

    /deep/ .main {
        display: flex;
        flex-direction: column;
        padding: 0;

        .com-card-header {
            padding: 0.2rem;
        }
    }

    .el-dropdown {
        margin-left: 0.12rem;

        i {
            font-size: 30px;
            color: #a7b6c3;
            cursor: pointer;
        }

        i.icon-gengduo1 {
            font-size: 18px;
        }

        i:hover {
            color: #d0d6da;
        }
    }

    section {
        display: flex;
        flex: 1;
        flex-direction: column;
        overflow: hidden;
    }

    .tabs {
        // height: 0.44rem;
        // padding: 0 0.2rem 0.15rem;
        margin-left: 0.2rem;
        font-size: 0.16rem;
        font-weight: 400;
        color: #909399;

        span {
            display: inline-block;
            height: 0.32rem;
            line-height: 0.32rem;
            cursor: pointer;
        }

        span.actived {
            font-weight: 500;
            color: #3595e1;
            border-bottom: 2px solid #3595e1;
        }

        span + span {
            margin-left: 0.2rem;
        }
    }

    /deep/ .el-scrollbar__wrap {
        overflow-x: hidden;
    }

    /deep/ .el-scrollbar__view {
        height: 100%;
    }

    .todo {
        &__body {
            display: flex;
        }

        &__body--main {
            display: flex;
            flex-direction: column;
            height: 100%;
        }

        &__tabs {
            display: flex;
            align-items: center;
            margin-bottom: 0.2rem;
        }

        &__tabs--menu {
            margin-right: 0.15rem;

            .icon-ego-menu {
                font-size: 0.24rem;
            }
        }
    }
}
</style>
